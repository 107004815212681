.cv_canvas {
  flex-grow: 1;
  display: flex;
  width: inherit;
  padding-bottom: 2rem;
  flex-flow: column nowrap;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(1rem) saturate(180%);
}
@media (min-width: 768px) {
  .cv_canvas {
    flex-flow: row nowrap;
  }
}
.cv_canvas .general {
  gap: 2rem 0;
  display: flex;
  position: relative;
  flex-flow: column nowrap;
}
@media (min-width: 768px) {
  .cv_canvas .general {
    width: 30%;
    min-width: 23rem;
  }
}
.cv_canvas .general .heroSection {
  display: inherit;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 0.5rem 0.5rem -0.4rem rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .cv_canvas .general .heroSection {
    gap: 1rem;
    border-radius: 0;
    box-shadow: none;
    flex-flow: row wrap;
    padding: 2rem 0 0 0;
    margin: 0 2rem;
  }
}
.cv_canvas .general .heroSection .desktopMeta {
  flex-grow: 1;
  display: flex;
  flex-flow: column;
}
.cv_canvas .general .heroSection .desktopMeta .idNumber {
  margin: 1rem 0;
  line-height: 1;
  color: #424242;
  font-weight: bold;
  padding: 0.3rem 1rem;
  border-radius: 2rem;
  max-width: max-content;
  background-color: #ced6e0;
}
.cv_canvas .general .heroSection .desktopMeta h2 {
  width: inherit;
  font-size: 2rem;
  white-space: nowrap;
}
.cv_canvas .general .heroSection .meta {
  top: 0;
  z-index: 5;
}
.cv_canvas .general .heroSection > picture {
  width: 100%;
  display: flex;
  aspect-ratio: 1;
  min-width: 20rem;
  overflow: hidden;
  background-image: url("https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80");
}
@media (min-width: 768px) {
  .cv_canvas .general .heroSection > picture {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 2;
    flex-basis: 30%;
    min-width: 10rem;
    max-width: 16rem;
    border-radius: 50%;
  }
}
.cv_canvas .general .heroSection > picture img {
  width: 100%;
  object-fit: cover;
}
.cv_canvas .general .heroSection .idents {
  bottom: 0;
  gap: 0 1rem;
  position: absolute;
  flex-flow: row wrap;
}
.cv_canvas .general .heroSection .idents h2, .cv_canvas .general .heroSection .idents h3 {
  white-space: nowrap;
}
.cv_canvas .general .heroSection .meta, .cv_canvas .general .heroSection .idents {
  width: 100%;
  display: flex;
  min-height: 2rem;
  position: absolute;
  align-items: center;
  padding: 0.5rem 2rem;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(1rem) saturate(200%);
}
.cv_canvas .general .heroSection .videoBtn {
  all: unset;
  gap: 0.5rem;
  bottom: 25%;
  padding: 2%;
  right: -100%;
  color: white;
  display: flex;
  line-height: 1;
  cursor: pointer;
  font-weight: bold;
  position: absolute;
  align-items: center;
  animation-delay: 1s;
  animation-duration: 0.5s;
  animation-name: showBtn;
  transition: all 0.2s ease-in;
  backdrop-filter: blur(0.5rem);
  border-radius: 2rem 0 0 2rem;
  animation-fill-mode: forwards;
  border: 2px solid transparent;
  border-right: none;
  animation-timing-function: ease;
  background-color: rgba(33, 150, 243, 0.8);
  box-shadow: 0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.2);
}
.cv_canvas .general .heroSection .videoBtn.hidden {
  right: 0;
  animation-duration: 0.5s;
  animation-name: hideBtn;
  transition: all 0.2s ease-in;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}
.cv_canvas .general .heroSection .videoBtn svg {
  font-size: 2rem;
  transition: inherit;
}
.cv_canvas .general .heroSection .videoBtn:hover {
  border-color: white;
}
.cv_canvas .general .heroSection .videoBtn:hover svg {
  transform: rotate(120deg);
}
@keyframes showBtn {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
@keyframes hideBtn {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}
.cv_canvas .general .heroSection .playerWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  backdrop-filter: blur(1rem);
  background-color: rgba(0, 0, 0, 0.9);
}
.cv_canvas .general .heroSection .playerWrapper > div {
  width: 100%;
  aspect-ratio: 16/9;
}
.cv_canvas .general .heroSection .playerWrapper svg {
  color: white;
  font-size: 5vw;
  position: absolute;
  bottom: calc(10.9375% - 2.5vw + 24px); /*TODO: think about margins*/
}
.cv_canvas .general .skillSection {
  gap: 1rem;
  display: flex;
  flex-flow: column nowrap;
  padding: 0 2rem;
}
.cv_canvas .general .skillSection > b {
  text-transform: uppercase;
}
.cv_canvas .general .skillSection .skillArray {
  display: flex;
  gap: 0.5rem 0.5rem;
  flex-flow: row wrap;
}
.cv_canvas .general .skillSection .skillArray > s {
  all: unset;
  line-height: 1;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.3rem 1rem;
  border-radius: 2rem;
  border: 1px solid #ced6e0;
}
.cv_canvas .general .skillSection .skillArray > s:hover {
  color: white;
  background-color: dodgerblue;
}
.cv_canvas .general .pricingSection {
  display: flex;
  row-gap: 1rem;
  overflow: hidden;
  border-radius: 0.5rem;
  flex-flow: column nowrap;
  margin: 0 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.15);
}
.cv_canvas .general .pricingSection .selector {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 2px solid #FFDA1D;
}
.cv_canvas .general .pricingSection .selector button {
  border: none;
  flex-grow: 1;
  cursor: pointer;
  min-height: 3rem;
  padding: 0.5rem 1rem;
  background-color: unset;
  text-transform: capitalize;
  transition: 0.3s all ease-in;
}
.cv_canvas .general .pricingSection .selector .active {
  background: linear-gradient(90.44deg, #FFDA1D 0.5%, #FFBA1B 100.05%);
  color: #424242;
}
.cv_canvas .general .pricingSection .priceDetails {
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}
.cv_canvas .general .pricingSection .priceDetails > span {
  text-align: center;
}
.cv_canvas .general .pricingSection .priceDetails .priceRow {
  line-height: 1;
  font-size: 3rem;
  font-weight: bold;
  width: min-content;
  position: relative;
}
.cv_canvas .general .pricingSection .priceDetails .priceRow sup {
  left: -2rem;
  position: absolute;
}
.cv_canvas .general .pricingSection .priceDetails .priceDetails {
  color: #ccc;
  padding: 0.5rem;
  font-size: 0.85rem;
}
.cv_canvas .general .pricingSection .bookBtn {
  border: none;
  color: white;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
  min-height: 3rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: dodgerblue;
}
.cv_canvas .general .pricingSection .bookBtn_inCart {
  border: none;
  color: white;
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
  min-height: 3rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background-color: #00E676;
}
.cv_canvas .secondary {
  display: flex;
  flex-flow: column nowrap;
  padding: 2rem;
  gap: 2rem;
  flex-grow: 1;
}
@media (min-width: 768px) {
  .cv_canvas .secondary {
    box-shadow: -0.1rem -0.5rem 0.5rem rgba(0, 0, 0, 0.15);
    width: 70%;
  }
  .cv_canvas .secondary .desktopPlayer {
    aspect-ratio: 16/9;
    border-radius: 1rem;
    overflow: hidden;
    max-width: 85%;
    align-self: center;
  }
}
.cv_canvas .secondary .eduWrapper,
.cv_canvas .secondary .expWrapper {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 1rem;
}
.cv_canvas .secondary .eduWrapper > span,
.cv_canvas .secondary .expWrapper > span {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: underline;
}
.cv_canvas .secondary .eduWrapper .eduContainer,
.cv_canvas .secondary .eduWrapper .expContainer,
.cv_canvas .secondary .expWrapper .eduContainer,
.cv_canvas .secondary .expWrapper .expContainer {
  display: inherit;
  flex-flow: inherit;
  row-gap: 1.5rem;
}
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem,
.cv_canvas .secondary .eduWrapper .expContainer .expItem,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem,
.cv_canvas .secondary .expWrapper .eduContainer .expItem,
.cv_canvas .secondary .expWrapper .expContainer .eduItem,
.cv_canvas .secondary .expWrapper .expContainer .expItem {
  display: inherit;
  flex-flow: inherit;
  row-gap: 0.25rem;
  border-left: 2px solid;
  border-image: linear-gradient(to bottom, transparent, dodgerblue, #FFBA1B, transparent) 1;
  padding-left: 1rem;
}
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .eduItem_name,
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .expItem_name,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .eduItem_name,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .expItem_name,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .eduItem_name,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .expItem_name,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .eduItem_name,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .expItem_name,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .eduItem_name,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .expItem_name,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .eduItem_name,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .expItem_name,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .eduItem_name,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .expItem_name,
.cv_canvas .secondary .expWrapper .expContainer .expItem .eduItem_name,
.cv_canvas .secondary .expWrapper .expContainer .expItem .expItem_name {
  font-size: 1.1rem;
}
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .eduItem_name > i,
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .expItem_name > i,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .eduItem_name > i,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .expItem_name > i,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .eduItem_name > i,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .expItem_name > i,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .eduItem_name > i,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .expItem_name > i,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .eduItem_name > i,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .expItem_name > i,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .eduItem_name > i,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .expItem_name > i,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .eduItem_name > i,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .expItem_name > i,
.cv_canvas .secondary .expWrapper .expContainer .expItem .eduItem_name > i,
.cv_canvas .secondary .expWrapper .expContainer .expItem .expItem_name > i {
  height: 1.1rem;
  background-color: #f0f0f0;
  padding: 0.125rem 1rem;
  margin-left: 1rem;
  line-height: 1;
  border-radius: 2rem;
  color: #424242;
  font-style: normal;
  font-size: 0.85rem;
  white-space: nowrap;
}
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .eduItem_grade > b,
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .expItem_position > b,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .eduItem_grade > b,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .expItem_position > b,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .eduItem_grade > b,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .expItem_position > b,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .eduItem_grade > b,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .expItem_position > b,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .eduItem_grade > b,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .expItem_position > b,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .eduItem_grade > b,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .expItem_position > b,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .eduItem_grade > b,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .expItem_position > b,
.cv_canvas .secondary .expWrapper .expContainer .expItem .eduItem_grade > b,
.cv_canvas .secondary .expWrapper .expContainer .expItem .expItem_position > b {
  color: #424242;
}
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .expItem_description,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .expItem_description,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .expItem_description,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .expItem_description,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .expItem_description,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .expItem_description,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .expItem_description,
.cv_canvas .secondary .expWrapper .expContainer .expItem .expItem_description {
  padding: 0.5rem 0;
}
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .eduItem_fields,
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .expItem_resps,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .eduItem_fields,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .expItem_resps,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .eduItem_fields,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .expItem_resps,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .eduItem_fields,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .expItem_resps,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .eduItem_fields,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .expItem_resps,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .eduItem_fields,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .expItem_resps,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .eduItem_fields,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .expItem_resps,
.cv_canvas .secondary .expWrapper .expContainer .expItem .eduItem_fields,
.cv_canvas .secondary .expWrapper .expContainer .expItem .expItem_resps {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
  color: #424242;
}
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .eduItem_fields > i,
.cv_canvas .secondary .eduWrapper .eduContainer .eduItem .expItem_resps > i,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .eduItem_fields > i,
.cv_canvas .secondary .eduWrapper .eduContainer .expItem .expItem_resps > i,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .eduItem_fields > i,
.cv_canvas .secondary .eduWrapper .expContainer .eduItem .expItem_resps > i,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .eduItem_fields > i,
.cv_canvas .secondary .eduWrapper .expContainer .expItem .expItem_resps > i,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .eduItem_fields > i,
.cv_canvas .secondary .expWrapper .eduContainer .eduItem .expItem_resps > i,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .eduItem_fields > i,
.cv_canvas .secondary .expWrapper .eduContainer .expItem .expItem_resps > i,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .eduItem_fields > i,
.cv_canvas .secondary .expWrapper .expContainer .eduItem .expItem_resps > i,
.cv_canvas .secondary .expWrapper .expContainer .expItem .eduItem_fields > i,
.cv_canvas .secondary .expWrapper .expContainer .expItem .expItem_resps > i {
  background-color: #f0f0f0;
  padding: 0.2rem 1rem;
  line-height: 1;
  border-radius: 2rem;
  color: #424242;
  font-style: normal;
  font-size: 0.85rem;
  white-space: nowrap;
}

