.messageCard{
  padding: 3rem;
  border-radius: .5rem;
  display: flex;
  justify-content: center;
  gap: .5rem;
  align-items: center;
  flex-flow: column nowrap;
  background-color: rgba(255,255,255,.8);
  backdrop-filter: blur(1rem) saturate(180%);
  box-shadow: 0 1rem 2rem rgba(0,0,0,.2);
  color: #424242;

  >span{
    display: block;
    font-weight: bold;
    &:first-child{
      font-size: 3rem;
    }
    &:last-child{
      padding-top: 1rem;
    }
  }
}