@import "../../assets/vars";

.card_skeleton {
	width: 20rem;
	display: flex;
	height: 30rem;
	border-radius: 1rem;
	flex-flow: column nowrap;
	box-shadow: 0 0.5rem 1.5rem -0.3rem rgba(0, 0, 0, 0.15);
	background-color: #fbfbfd;

	> header,
	footer {
		align-items: center;
		display: flex;
		width: 100%;
		height: 3rem;
		padding: 0 1rem;

		> span {
			width: 4rem;
			height: 1.4rem;
			border-radius: 2rem;
			background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
			animation: 1.5s shine linear infinite;
			background-size: 200% 100%;
		}
	}

	> section {
		&.skeleton_avatar {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			flex-flow: column nowrap;
			padding-bottom: 0.65rem;

			> div {
				width: 13rem;
				display: flex;
				aspect-ratio: 1;
				border-radius: 50%;
				background: #eee;
				background: linear-gradient(
					110deg,
					#ececec 8%,
					#f5f5f5 18%,
					#ececec 33%
				);
				animation: 1.5s shine linear infinite;
				background-size: 200% 100%;
			}

			> span {
				bottom: 0;
				width: 6rem;
				height: 1.6rem;
				position: absolute;
				border-radius: 2rem;
				background: #eee;
				background: linear-gradient(
					110deg,
					#ececec 8%,
					#f5f5f5 18%,
					#ececec 33%
				);
				animation: 1.5s shine linear infinite;
				background-size: 200% 100%;
			}
		}

		&.skeleton_idents {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-flow: column nowrap;
			gap: 0.3rem;
			padding: 0.5rem 0;

			div {
				border-radius: 2rem;
				min-height: 1.3rem;
				width: 10rem;
				background: linear-gradient(
					110deg,
					#ececec 8%,
					#f5f5f5 18%,
					#ececec 33%
				);
				animation: 1.5s shine linear infinite;
				background-size: 200% 100%;

				&:last-child {
					min-height: 1rem;
					width: 7rem;
				}
			}
		}

		&.skeleton_skills {
			flex-grow: 1;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: center;
			padding: 1rem;
			gap: 0.2rem 1rem;

			> span {
				width: 4rem;
				height: 1.4rem;
				border-radius: 2rem;
				background: linear-gradient(
					110deg,
					#ececec 8%,
					#f5f5f5 18%,
					#ececec 33%
				);
				animation: 1.5s shine linear infinite;
				background-size: 200% 100%;

				&:nth-child(odd) {
					width: 5rem;
				}
			}
		}
	}

	> footer {
		justify-content: center;
		gap: 1rem;

		> span {
			width: 7rem;
		}

		hr {
			all: unset;
			background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
			animation: 1.5s shine linear infinite;
			background-size: 200% 100%;
			width: 15%;
			height: 1px;
		}
	}

	@keyframes shine {
		to {
			background-position-x: -200%;
		}
	}
}

.card {
	min-width: 20rem;
	width: 20rem;
	display: flex;
	height: 30rem;
	overflow: hidden;
	border-radius: 1rem;
	flex-flow: column nowrap;
	background-color: $cardBgColor;
	//backdrop-filter: blur(1rem) saturate(160%);
	transition: all 0.1s ease-in;
	box-shadow: 0 0.2rem 1.5rem -0.3rem rgba(0, 0, 0, 0.2);

	> header,
	footer {
		display: flex;
		width: inherit;
		min-height: 3rem;
		padding: 0 1rem;
	}

	> header {
		justify-content: space-between;
		align-items: center;
		user-select: none;

		> span {
			color: rgba(0, 0, 0, 0.2);
			font-weight: bold;
			transition: all 0.2s ease-in;
		}
	}

	> section {
		&.avatar {
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;
			flex-flow: column nowrap;
			padding-bottom: 0.65rem;
			cursor: pointer;

			> picture {
				pointer-events: none;
				width: 13rem;
				display: flex;
				aspect-ratio: 1;
				border-radius: 50%;
				overflow: hidden;
				align-items: center;
				justify-content: center;
				background: $avatarBgColor;

				span {
					width: inherit;
					height: 100%;
					img {
						pointer-events: none;
						width: inherit;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			> span {
				bottom: 0;
				transform-origin: center;
				//border-left: .5px solid rgba(255, 255, 255, .8);
				//border-top: .5px solid rgba(255, 255, 255, .8);
				//border-bottom: .5px solid rgba(0, 0, 0, .1);
				//border-right: .5px solid rgba(0, 0, 0, .1);
				//backdrop-filter: blur(1rem) saturate(180%);
				line-height: 1;
				position: absolute;
				padding: 0.3rem 1rem;
				border-radius: 2rem;
				text-transform: uppercase;
				background-color: rgba(255, 255, 255, 0.95);
			}
		}

		&.idents {
			width: inherit;
			padding: 0.5rem 1rem;
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}

		&.skills {
			flex-grow: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 0 1rem;

			.wrapper {
				display: inherit;
				justify-content: inherit;
				align-items: inherit;
				gap: 0.5rem 0.5rem;
				flex-flow: row wrap;

				> s {
					all: unset;
					white-space: nowrap;
					line-height: 1;
					padding: 0.3rem 0.8rem;
					border-radius: 2rem;
					font-size: 0.9rem;
					border: 1px solid #ced6e0;
					user-select: none;

					&.vacant {
						border-color: $blue;
						color: $blue;
					}

					&.hiddenCounter {
						border-color: $hiddenCounterColor;
						background: $hiddenCounterColor;
						color: #fff;
					}
				}
			}
		}
	}

	> footer {
		justify-content: center;
		align-items: center;
		gap: 0.5rem;
		cursor: pointer;

		span {
			transition: all 0.2s ease-in;
		}

		hr {
			width: 15%;
			height: 1px;
			border: none;
			background-color: #ccc;
			border-radius: 1rem;
			transition: all 0.2s ease-in;
		}

		&:hover {
			span {
				color: $blue;
			}
		}
	}

	&:hover {
		box-shadow: 0 0.1rem 0.6rem -0.2rem rgba(0, 0, 0, 0.3);

		header {
			span {
				color: $blue;
			}
		}

		footer {
			hr {
				width: 30%;
				background-color: $blue;
			}
		}
	}
}
