.wrapper {
	background-color: #fbfbfd;
	width: min(50rem, 100%);
	min-height: calc(60vh);
	border-radius: 1rem;
	padding: 2rem;
	margin: 2rem 0;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;

	.employeeRow {
		display: flex;
		width: 100%;
		column-gap: 0.3rem;
		row-gap: 1rem;
		align-items: center;
		padding: 0.5rem;
		border: 1px solid #ccc;
		border-radius: 0.5rem;

		picture {
			width: 6rem;
			aspect-ratio: 1;
			overflow: hidden;
			display: flex;
			border-radius: 6rem;
			background-color: #61efff;
			margin-right: 1rem;
			span {
				display: inherit;
				width: inherit;
				height: inherit;

				img {
					width: 100%;
					object-fit: cover;
				}
			}
		}

		.idents {
			display: flex;
			flex: 1 2 auto;
			flex-flow: column nowrap;
			justify-content: center;
		}
		.price {
			display: flex;
			flex-flow: column nowrap;
			flex: 0 0 20%;
			align-items: center;
			justify-content: center;
			gap: 0.5rem;
			color: #000000;
			text-align: center;
			border-left: 1px solid #ccc;
			padding: 0rem 0.4rem;
			span {
				text-align: center;
			}
		}
		.buttons {
			display: flex;
			flex: 0 1 30%;
			justify-content: flex-end;
			align-items: flex-end;
			align-self: stretch;
			flex-flow: column nowrap;
			gap: 0.5rem;
		}
		@media (max-width: 578px) {
			display: grid;
			grid-template-columns: fit-content(20%) auto auto;
			grid-template-rows: auto auto;
			grid-template-areas:
				"pic id price"
				"btns btns btns";

			picture {
				grid-area: pic;
			}
			.idents {
				grid-area: id;
			}
			.price {
				grid-area: price;
			}
			.buttons {
				grid-area: btns;
			}
		}
	}

	.noEmployees {
		display: flex;
		width: 100%;
		gap: 1rem;
		align-items: center;
		justify-content: center;
		padding: 1rem;
		border: 1px solid #ccc;
		border-radius: 0.5rem;
		font-weight: bold;
		color: #424242;

		> em {
			text-align: center;
			line-height: 1.5;
			font-size: 1.2rem;
		}
	}
}
