.wrapper {
  background-color: #fbfbfd;
  width: min(50rem, 100%);
  min-height: 60vh;
  border-radius: 1rem;
  padding: 2rem;
  margin: 2rem 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.wrapper .employeeRow {
  display: flex;
  width: 100%;
  -moz-column-gap: 0.3rem;
       column-gap: 0.3rem;
  row-gap: 1rem;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}
.wrapper .employeeRow picture {
  width: 6rem;
  aspect-ratio: 1;
  overflow: hidden;
  display: flex;
  border-radius: 6rem;
  background-color: #61efff;
  margin-right: 1rem;
}
.wrapper .employeeRow picture span {
  display: inherit;
  width: inherit;
  height: inherit;
}
.wrapper .employeeRow picture span img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.wrapper .employeeRow .idents {
  display: flex;
  flex: 1 2 auto;
  flex-flow: column nowrap;
  justify-content: center;
}
.wrapper .employeeRow .price {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 20%;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #000000;
  text-align: center;
  border-left: 1px solid #ccc;
  padding: 0rem 0.4rem;
}
.wrapper .employeeRow .price span {
  text-align: center;
}
.wrapper .employeeRow .buttons {
  display: flex;
  flex: 0 1 30%;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
  flex-flow: column nowrap;
  gap: 0.5rem;
}
@media (max-width: 578px) {
  .wrapper .employeeRow {
    display: grid;
    grid-template-columns: fit-content(20%) auto auto;
    grid-template-rows: auto auto;
    grid-template-areas: "pic id price" "btns btns btns";
  }
  .wrapper .employeeRow picture {
    grid-area: pic;
  }
  .wrapper .employeeRow .idents {
    grid-area: id;
  }
  .wrapper .employeeRow .price {
    grid-area: price;
  }
  .wrapper .employeeRow .buttons {
    grid-area: btns;
  }
}
.wrapper .noEmployees {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  font-weight: bold;
  color: #424242;
}
.wrapper .noEmployees > em {
  text-align: center;
  line-height: 1.5;
  font-size: 1.2rem;
}/*# sourceMappingURL=cart.module.css.map */