.wrapper {
	width: inherit;
	height: inherit;
	display: flex;
	justify-content: space-evenly;
	flex-flow: row wrap;
	gap: 2rem 2rem;
	padding: 2rem 2rem 1rem 2rem;
	position: relative;
}

.noStuff{
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	gap: 2rem;
	font-size: 1rem;
	font-weight: 500;
	text-align: center;
	& > i{
		font-size: clamp(5rem, 8vw, 7rem);
	}
}

.loadingChecker {
	width: 75%;
	height: max-content;
	padding: 1rem 5rem;
	margin: 2rem;
}

.staffLoaded {
	width: 75%;
	height: max-content;
	padding: 1rem;
	margin: 2rem;
	border-radius: 0.5rem;
	background-color: dodgerblue;
	color: white;
	border: 2px solid white;
	user-select: none;
	text-align: center;

	span {
		b {
			text-decoration: underline;
			cursor: pointer;
		}
	}
}
.goHome{
	
}