@import "../../assets/vars";

@mixin canvas {
    display: flex;
    opacity: 0;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,.9);
    backdrop-filter: blur(1rem) saturate(160%);
    padding: 1rem;
    position: fixed;
    gap: 1rem;
    top: 0;
    left: -100%;
    animation-duration: .3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    z-index: 2;
}

.filter_canvas{
    @include canvas;

    >div{
        width: 60%;
        max-width: 30rem;
    }

    .filterButtons{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;

        >button{
            all: unset;
            cursor: pointer;
            padding: .5rem 2rem;
            background-color: $blue;
            color: white;
            border-radius: .2rem;
        }
    }

    .domainFieldset{
        width: 60%;
        max-width: 30rem;
        padding: 1rem;
        border-radius: .3rem;
        border: 1px solid #ccc;

        > legend{
            padding: 0 .5rem;
        }

        >p {
            font-size: .9rem;
            color: #424242;
            padding-bottom: 1rem;
        }

        .domainTypes{
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: .5rem 0;

            >div{
                display: inherit;
                gap: .5rem;
            }
        }
    }
}

.open{
    animation-name: opening;
}

.closed{
    animation-name: closing;
}


@keyframes opening {
    from{
        left: -100%;
        opacity: 0;
    }
    to{
        left: 0;
        opacity: 1;
    }
}

@keyframes closing {
    from{
        left: 0;
        opacity: 1;
    }
    to{
        left: -100%;
        opacity: 0;
    }
}
